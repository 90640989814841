import React, { useState, useEffect, useRef } from "react";
import { View, Text, ScrollView, ImageBackground, Pressable, StyleSheet, Animated } from "react-native";
import { useNavigation } from "@react-navigation/native";
import styles from "../Styles/EventsStyles";
import NavbarHeader from "../Navigation/NavBarHeader";
import Footer from "../Navigation/Footer";
import SlideShow from "../Widgets/SlideShow";
import Pool from "../Widgets/Pool";
import { getFirestore, collection, getDocs, query, where } from "firebase/firestore";
import { addDays, format } from "date-fns";
import { Link, animateScroll as scroll } from "react-scroll";
import { usePageAnimations } from '../hooks/usePageAnimations';

const Events = () => {
	const navigation = useNavigation();
	const scrollViewRef = useRef(null);
	const [scrollY, setScrollY] = useState(0);
	const [events, setEvents] = useState([]);
	const [clubEvents, setClubEvents] = useState([]);
	const { getHeaderStyle, getContentStyle, getButtonStyle } = usePageAnimations();

	const [slides, setSlides] = useState([
		// Define your static slides here, and these will be merged with dynamic events later
		{ image: require("../assets/Slides/SomersetRoom2.jpg"), roomName: "The Somerset Room", roomDescription: "Expansive windows overlooking the pool, gazebo, and fairways impart a light and gracious atmosphere in Somerset. The Somerset Room can accommodate up to 60." },
		{ image: require("../assets/Slides/Garden Room.jpg"), roomName: "The Garden Room", roomDescription: "Cozy and intimate, The Garden Room is perfect for smaller gatherings or buffet set up. Accented by double French doors and windows overlooking the pool, the Garden Room can accommodate up to 30." },
		{ image: require("../assets/Slides/Wheeler Room.jpg"), roomName: "The Wheeler Room", roomDescription: "The Wheeler Room is located on the second floor and seats 50 people" },
		{ image: require("../assets/Slides/BallroomLadies.jpg"), roomName: "The Ballroom", roomDescription: "Graced by a soaring stone fireplace and grand staircase, The Ballroom adds graceful elegance to any occasion. The Ballroom can accommodate up to 100 people. 120 with no room for entertainment." },
	]);

	useEffect(() => {
		fetchEvents();
		fetchClubEvents();
	}, []);

	const calculateNextOccurrence = (eventDate, isDaily, isWeekly, isBiWeekly, isMonthly) => {
		let nextDate = new Date(eventDate);
		const today = new Date();
		while (nextDate < today) {
			if (isDaily) {
				nextDate.setDate(nextDate.getDate() + 1);
			} else if (isWeekly) {
				nextDate.setDate(nextDate.getDate() + 7);
			} else if (isBiWeekly) {
				nextDate.setDate(nextDate.getDate() + 14);
			} else if (isMonthly) {
				nextDate.setMonth(nextDate.getMonth() + 1);
			} else {
				break;
			}
		}
		return nextDate;
	};

	const fetchEvents = async () => {
		const db = getFirestore();
		const eventsSnapshot = await getDocs(collection(db, "events"));
		const fetchedEvents = eventsSnapshot.docs.map((doc) => {
			const data = doc.data();
			let eventDate = new Date(data.date);
			// Add a day to account for timezone issues
			eventDate = addDays(eventDate, 1);
			// Assuming you store the recurrence options in the event data:
			const nextOccurrence = calculateNextOccurrence(eventDate, data.isDaily, data.isWeekly, data.isBiWeekly, data.isMonthly);
			return {
				...data,
				id: doc.id,
				date: format(nextOccurrence, "MMMM dd, yyyy"),
			};
		});
		setEvents(fetchedEvents);
	};

	const fetchClubEvents = async () => {
		const db = getFirestore();
		const eventsSnapshot = await getDocs(collection(db, "club_events"));
		let fetchedClubEvents = eventsSnapshot.docs.map((doc) => {
			const data = doc.data();
			let eventDate = new Date(data.date);
			// Add a day to account for timezone issues
			eventDate = addDays(eventDate, 1);
			return {
				...data,
				id: doc.id,
				date: eventDate, // Keep the date as a Date object for sorting
			};
		});

		// Sort events by date
		fetchedClubEvents.sort((a, b) => a.date - b.date);

		// Only keep the top 4 events and format the date
		fetchedClubEvents = fetchedClubEvents.slice(0, 4).map((event) => ({
			...event,
			date: format(event.date, "MMMM dd, yyyy"),
		}));

		setClubEvents(fetchedClubEvents);
	};

	const handleLinkPress = (pageName, params) => {
		navigation.navigate(pageName, params);
	};

	const handleScroll = (event) => {
		setScrollY(event.nativeEvent.contentOffset.y);
	};

	return (
		<View style={styles.body}>
			<View style={{ position: "sticky", top: 0, zIndex: 1000 }}>
				<NavbarHeader />
			</View>
			<ScrollView style={styles.body} onScroll={handleScroll} scrollEventThrottle={16} ref={scrollViewRef}>
				{/* Dynamically Rendered Events */}
				<View style={styles.section}>
					<ImageBackground source={require("../assets/Example_Background.png")} style={styles.background}>
						<Animated.View style={getHeaderStyle()}>
							<Text style={styles.headerText}>Events</Text>
						</Animated.View>

						<Animated.View style={getContentStyle()}>
							<View style={styles.inlineNavBar}>
								{/* Home link */}
								{/* <Link to="Calendar" smooth={true} duration={500} offset={-60}>
									<Text style={styles.navBarText}>Club Events</Text>
								</Link> */}

								{/* Banquets link */}
								<Link to="Banquets" smooth={true} duration={500} offset={-60}>
									<Text style={styles.navBarText}>Banquets & Dining</Text>
								</Link>

								{/* Events link */}
								<Link to="The Pool" smooth={true} duration={500} offset={-60}>
									<Text style={styles.navBarText}>The Pool</Text>
								</Link>
							</View>
						</Animated.View>

						<Animated.View style={getContentStyle()}>
							{events.length > 0 ? (
								events.map((event) => (
									<Pressable key={event.id} onPress={() => handleLinkPress("EventDetail", { eventId: event.id })}>
										<View style={styles.innerContainer}>
											<Text style={styles.containerHeaderText}>{event.title}</Text>
											<Text style={styles.containerDescriptionText}>{event.date}</Text>
											<Text style={styles.containerDescriptionText}>{event.description}</Text>
										</View>
									</Pressable>
								))
							) : (
								<View style={styles.eventsContainer}>
									<Text style={styles.containerDescriptionText}>No upcoming events. Check back soon!</Text>
								</View>
							)}
						</Animated.View>
					</ImageBackground>
				</View>

				{/* Calendar Section (You can dynamically populate this or keep it static as before) */}
				{/* <View id="Calendar" style={styles.section}>
					<ImageBackground source={require("../assets/Example_Background_2.png")} style={styles.background}>
						<Text style={styles.headerText}>Club Events</Text>
						{clubEvents.length > 0 ? (
							clubEvents.map((clubEvent) => (
								<Pressable key={clubEvent.id} onPress={() => handleLinkPress("EventDetail", { eventId: clubEvent.id })}>
									<View style={styles.innerContainer1}>
										<Text style={styles.containerHeaderText1}>{clubEvent.title}</Text>
										<Text style={styles.containerDescriptionText1}>{clubEvent.date}</Text>
										<Text style={styles.containerDescriptionText1}>{clubEvent.description}</Text>
									</View>
								</Pressable>
							))
						) : (
							<View style={styles.eventsContainer}>
								<Text style={styles.containerDescriptionText}>No upcoming club events. Check back soon!</Text>
							</View>
						)}
					</ImageBackground>
				</View> */}

				<Animated.View style={getContentStyle()}>
					<View id="Banquets" style={styles.section}>
						<ImageBackground source={require("../assets/Example_Background_2.png")} style={styles.background}>
							{/* Continued from SlideShow component */}
							<SlideShow slides={slides} />
						</ImageBackground>
					</View>
				</Animated.View>

				<Animated.View style={getContentStyle()}>
					{/* Pool Section */}
					<View id="The Pool" style={styles.section}>
						<ImageBackground source={require("../assets/Example_Background_2.png")} style={styles.background}>
							<Text style={styles.headerText}>The Pool</Text>
							{/* Pool Component */}
							<Pool />
						</ImageBackground>
					</View>
				</Animated.View>

				{/* Footer */}
				<Footer />
			</ScrollView>
		</View>
	);
};

export default Events;
