import React, { useState, useRef, useEffect } from "react";
import { View, Text, ScrollView, ImageBackground, Pressable, Animated, Linking } from "react-native";
import { getFirestore, collection, addDoc, getDocs, deleteDoc, doc, updateDoc } from "firebase/firestore";
import { useNavigation } from "@react-navigation/native";
import styles from "../Styles/HomeStyles";
import NavbarHeader from "../Navigation/NavBarHeader";
import Footer from "../Navigation/Footer";
import SocialMediaWidget from "../Widgets/SocialMediaWidget";
import NewsletterPopup from "../Widgets/NewsletterPopup";
import VideoBackground from "../Widgets/VideoBackground";


const Home = () => {
	const navigation = useNavigation();
	const videos = ["../assets/Videos/SCC1.mp4", "../assets/Videos/SCC2.mp4", "../assets/Videos/SCC3.mp4", "../assets/Videos/SCC4.mp4", "../assets/Videos/SCC5.mp4", "../assets/Videos/SCC6.mp4"];
	const videoId = "aZUkTrJSnjA"; // Replace with the desired YouTube video ID

	const [scrollY, setScrollY] = useState(new Animated.Value(0)); // Initialize an animated value
	const [isContainerVisible, setIsContainerVisible] = useState(true); // Initially set to true
	const [containerOpacity] = useState(new Animated.Value(1)); // Fully visible by default
	const [isMuted, setIsMuted] = useState(true);
	const [showNewsletterPopup, setShowNewsletterPopup] = useState(false);
	const [currentVideoIndex, setCurrentVideoIndex] = useState(0); // Start from the first video
	const [messages, setMessages] = useState([]);
	// Add state to control the visibility of the MessageBoard
	const [isMessageBoardVisible, setIsMessageBoardVisible] = useState(true);

	// Use an animated value for the message board's translation
	const messageBoardTranslateY = useRef(new Animated.Value(-100)).current; // Initial negative value to hide the message board off-screen

	// Dynamically adjust the style of the button container
	const buttonContainerStyle = isMessageBoardVisible ? { marginTop: 60 } : {}; // Increase top margin when message board is visible

	// Add animation values
	const headerAnimation = useRef(new Animated.Value(0)).current;
	const navBarAnimation = useRef(new Animated.Value(0)).current;
	const buttonsAnimation = useRef(new Animated.Value(0)).current;

	// Add animation sequence
	useEffect(() => {
		Animated.stagger(400, [
			Animated.spring(headerAnimation, {
				toValue: 1,
				friction: 12,
				tension: 35,
				useNativeDriver: true,
			}),
			Animated.spring(navBarAnimation, {
				toValue: 1,
				friction: 12,
				tension: 35,
				useNativeDriver: true,
			}),
			Animated.spring(buttonsAnimation, {
				toValue: 1,
				friction: 12,
				tension: 35,
				useNativeDriver: true,
			}),
		]).start();
	}, []);

	const handleVideoEnd = () => {
		// This function gets called when the current video ends.
		setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videos.length);
	};

	// #region - Message Board Functions & Widget
	useEffect(() => {
		const fetchMessages = async () => {
			const db = getFirestore();
			const querySnapshot = await getDocs(collection(db, "messages"));
			const fetchedMessages = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
			setMessages(fetchedMessages);
		};

  fetchMessages();
}, []);


useEffect(() => {
	if (isMessageBoardVisible) {
					Animated.timing(messageBoardTranslateY, {
									toValue: 0,
									duration: 2500,
									useNativeDriver: true,
					}).start();
	} else {
					Animated.timing(messageBoardTranslateY, {
									toValue: -100,
									duration: 2500,
									useNativeDriver: true,
					}).start();
	}
}, [isMessageBoardVisible, messageBoardTranslateY]);

	const MessageBoard = ({ messages }) => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const fadeAnim = useRef(new Animated.Value(0)).current; // Opacity for fade animation
  const slideAnim = useRef(new Animated.Value(300)).current; // Position for slide animation
  const [loopCompleted, setLoopCompleted] = useState(false); // Track if all messages have been shown

  useEffect(() => {
    // Reset the flag whenever the messages list changes
    setLoopCompleted(false);
  }, [messages]);

  const showMessage = (index) => {
    // Reset slide position to the starting point
    slideAnim.setValue(300);

    // Start the slide-in animation
    Animated.timing(slideAnim, {
      toValue: 0,
      duration: 500,
      useNativeDriver: true,
    }).start();

    // Fade in the current message
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 500,
      useNativeDriver: true,
    }).start(() => {
      // Wait for the message's duration then fade out
      setTimeout(() => {
        Animated.timing(fadeAnim, {
          toValue: 0,
          duration: 500,
          useNativeDriver: true,
        }).start(() => {
          // Prepare to show the next message
          const nextIndex = (index + 1) % messages.length;
          if (nextIndex === 0) {
            // All messages have been shown, set the flag
            setLoopCompleted(true);
          }
          setCurrentMessageIndex(nextIndex);
          showMessage(nextIndex);
        });
      }, messages[index].duration);
    });
  };

  useEffect(() => {
    if (messages.length > 0) {
      showMessage(0);
    }
  }, [messages]);

  if (!messages.length) return null;

  if (loopCompleted) {
    // Perform an action when all messages have been shown, e.g., hide the header bar
    // setIsContainerVisible(false); // Assuming this function will hide the header bar
  }

  return (
    <Animated.View
      style={[
        styles.messageBoardContainer,
        {
          transform: [{ translateY: messageBoardTranslateY }],
        },
      ]}>
      <Pressable onPress={() => setIsMessageBoardVisible(false)} style={{}}>
        <Text style={{ color: "#fff", fontSize: 18, top: 0 }}>✕</Text>
      </Pressable>
      <Animated.View style={{ opacity: fadeAnim, transform: [{ translateX: slideAnim }] }}>
        <Text style={styles.messageText}>{messages[currentMessageIndex].message}</Text>
      </Animated.View>
    </Animated.View>
  );
};


	//#endregion

	// #region - Functions
	const handleScroll = Animated.event([{ nativeEvent: { contentOffset: { y: scrollY } } }], { useNativeDriver: false });

	const navbarTranslateY = scrollY.interpolate({
		inputRange: [0, 35],
		outputRange: [-300, 0], // Adjust the value for the desired slide-in distance
		extrapolate: "clamp",
	});

 const handleHideContainer = () => {
		console.log("Hover In");
		Animated.timing(containerOpacity, {
				toValue: 0,
				duration: 500,
				useNativeDriver: true,
		}).start();
};

	const handleShowContainer = () => {
		console.log("Hover Out");
		Animated.timing(containerOpacity, {
			toValue: 1,
			duration: 500,
			useNativeDriver: true,
		}).start();
	};

	const handleToggleContainer = () => {
		if (isContainerVisible === true) {
			handleHideContainer();
			setIsContainerVisible(false);
			console.log(isContainerVisible);
		} else if (isContainerVisible === false) {
			handleShowContainer();
			setIsContainerVisible(true);
		}
	};

	const handleLinkPress = (pageName) => {
		console.log(`Navigating to ${pageName}`);
		setIsMuted(true); // Mute the video when navigating away
		navigation.navigate(pageName);
	};

	const handlePdfDownload = async (pdfUrl) => {
		// Check if the link is usable
		const canOpen = await Linking.canOpenURL(pdfUrl);
		if (canOpen) {
			// Open the URL if it is usable
			await Linking.openURL(pdfUrl);
		} else {
			console.error("Unable to open the URL: ", pdfUrl);
		}
	};
	// #endregion

	// #region - Show Newsletter Popup
	// useEffect(() => {
	// 	const timer = setTimeout(() => {
	// 		setShowNewsletterPopup(true);
	// 	}, 6000);

	// 	return () => clearTimeout(timer);
	// }, []);
	//#endregion

	// #region - Render
	return (
		<View style={{ flex: 1 }}>
			<ScrollView
				style={styles.body}
				onScroll={handleScroll}
				scrollEventThrottle={16} // Set the throttle value as needed
			>
				{/* Home */}
				<View style={styles.section}>
					<ImageBackground source={require("../assets/Example_Background.png")} style={styles.background}>
						{/* <YoutubeBackground videoId={videoId} isMuted={isMuted} /> */}
						<VideoBackground key={currentVideoIndex} isMuted={isMuted} src={videos[currentVideoIndex]} onEnded={handleVideoEnd} />

						<View style={[buttonContainerStyle, { position: "relative", width: "100%", height: "100vh", zIndex: 0 }]}>
							{/* New button for hiding the container */}

							<Pressable style={styles.showVideoButton} onHoverIn={handleHideContainer} onHoverOut={handleShowContainer} onPress={handleToggleContainer}>
								{isContainerVisible ? <Text style={styles.showVideo}>Show</Text> : <Text style={styles.showVideo}>Hide</Text>}
								<Text style={styles.showVideo}>Video</Text>
							</Pressable>

							{/* Mute/Unmute Button */}
							<Pressable style={styles.muteVideoButton} onPress={() => setIsMuted(!isMuted)}>
								<Text style={styles.muteVideoText}>{isMuted ? "Unmute" : "Mute"} Video</Text>
							</Pressable>

							{/* Message Board Popup */}
							{messages && <MessageBoard messages={messages} />}

							{/* Conditionally render YoutubeBackground based on isContainerVisible */}

							{isContainerVisible && (
								<Animated.View
									style={{
										opacity: containerOpacity,
									}}>
									<Animated.View style={{
										opacity: headerAnimation,
										transform: [
											{ translateY: headerAnimation.interpolate({
												inputRange: [0, 1],
												outputRange: [-50, 0]
											})}
										]
									}}>
										<Text style={styles.titleText}>Somerset Country Club</Text>
									</Animated.View>

									<Animated.View style={{
										opacity: navBarAnimation,
										transform: [
											{ translateY: navBarAnimation.interpolate({
												inputRange: [0, 1],
												outputRange: [50, 0]
											})}
										]
									}}>
										<View style={styles.navBar}>
											{/* Home link */}
											<Pressable onPress={() => handleLinkPress("Home")} style={styles.link}>
												<Text style={styles.navBarText}>Home</Text>
											</Pressable>

											{/* Golf link */}
											<Pressable onPress={() => handleLinkPress("Golf")} style={styles.link}>
												<Text style={styles.navBarText}>Golf</Text>
											</Pressable>

											{/* Events link */}
											<Pressable onPress={() => handleLinkPress("Events")} style={styles.link}>
												<Text style={styles.navBarText}>Events</Text>
											</Pressable>

											{/* Contact link */}
											<Pressable onPress={() => handleLinkPress("Contact")} style={styles.link}>
												<Text style={styles.navBarText}>Contact</Text>
											</Pressable>
										</View>
									</Animated.View>
								</Animated.View>
							)}
						</View>

						<SocialMediaWidget style={styles.socialMediaWidget} />
					</ImageBackground>
				</View>
				{/* Home */}

				{/* Upcoming Events */}
				<View style={styles.section}>
					<ImageBackground source={require("../assets/clubhouse.png")} style={styles.background}>
						<Pressable onPress={() => handleLinkPress("Events")}>
							<Text style={styles.upcomingEvents}>Visit The Club</Text>
							{/* Main container */}
							<View style={styles.mainContainer}>
								{/* Text */}
								<Text style={styles.mainContainerText}>There's always a reason to visit the club. Challenge yourself by playing a round of golf on our pristine course. Enjoy an incredible dinner with your family. Relax at the pool on a hot summer day. Attend a concert or charity event. Host a bridal shower or party. The club provides fun-filled experiences in a family friendly atmosphere. Create cherished memories and lifelong friendships here at Somerset Country Club.</Text>
							</View>
						</Pressable>

						{/* Buttons */}
						<Animated.View style={{
							opacity: buttonsAnimation,
							transform: [
								{ translateY: buttonsAnimation.interpolate({
									inputRange: [0, 1],
									outputRange: [50, 0]
								})}
							]
						}}>
							<View style={styles.buttonContainer}>
								<Pressable style={styles.button} onPress={() => handleLinkPress("Golf")}>
									<Text style={styles.buttonText}>Golf</Text>
								</Pressable>
								<Pressable style={styles.button} onPress={() => handleLinkPress("Events")}>
									<Text style={styles.buttonText}>Events & Dining</Text>
								</Pressable>
								<Pressable style={styles.button} onPress={() => handleLinkPress("Contact")}>
									<Text style={styles.buttonText}>Contact Us</Text>
								</Pressable>
							</View>
						</Animated.View>
					</ImageBackground>
				</View>
				{/* Upcoming Events */}

				{/* Conditionally render HeaderNavBar with animation */}
				<Animated.View
					style={[
						{
							transform: `translateY(${navbarTranslateY}px)`, // Assuming navbarTranslateY is a number
						},
					]}>
					{scrollY >= 35 && <NavbarHeader />}
				</Animated.View>
				<Footer />
			</ScrollView>

			{/* Newsletter Popup Widget */}
			<NewsletterPopup isVisible={showNewsletterPopup} onClose={() => setShowNewsletterPopup(false)} />
		</View>
	);
	// #endregion
};

export default Home;
