import React, { useState, useEffect } from "react";
import { View, Text, ImageBackground, TouchableOpacity, StyleSheet, Dimensions, Pressable, Linking } from "react-native";
import { FontAwesome } from '@expo/vector-icons';

const SlideShow = ({ slides }) => {
	const [currentSlide, setCurrentSlide] = useState(0);
	const [windowDimensions, setWindowDimensions] = useState(Dimensions.get("window"));

	const handlePdfDownload = async (pdfUrl) => {
		try {
			const response = await fetch(pdfUrl);
			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}

			const blob = await response.blob();
			const url = window.URL.createObjectURL(blob);

			// Create a new anchor element
			const a = document.createElement("a");
			a.href = url;
			a.download = pdfUrl.split("/").pop(); // Set the file name
			document.body.appendChild(a);
			a.click();

			// Clean up
			window.URL.revokeObjectURL(url);
			document.body.removeChild(a);
		} catch (error) {
			console.error("Download error:", error.message);
		}
	};

	const handleBooking = () => {
		// Open the phone app with the provided phone number
		Linking.openURL("tel:(814)445-5200");
	};

	useEffect(() => {
		const subscription = Dimensions.addEventListener("change", ({ window }) => {
			setWindowDimensions(window);
		});

		return () => subscription?.remove();
	}, []);



	return (
		<ImageBackground source={slides[currentSlide].image} style={styles.slideShow}>
			<View style={{ ...styles.rightCorner }}>

				<Text style={styles.rightCornerTitle}>Banquets and Dining</Text>
				<View style={{ ...styles.row }}>
					<Pressable
						style={styles.button}
						onPress={() => {
							handleBooking();
						}}>
						<Text style={styles.buttonText}>Book Now</Text>
					</Pressable>
					<Pressable
						style={styles.button}
						onPress={() => {
							handlePdfDownload("../assets/2023 Menu.pdf");
						}}>
						<Text style={styles.buttonText}>See Menus</Text>
					</Pressable>
				</View>
			</View>

			<View style={styles.leftCorner}>
				<Text style={styles.leftCornerTitle}>{slides[currentSlide].roomName}</Text>
				<Text style={styles.leftCornerDescription}>{slides[currentSlide].roomDescription}</Text>
			</View>

			<TouchableOpacity style={styles.prevIcon} onPress={() => setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length)}>
				<FontAwesome name="arrow-left" size={isSmallDevice ? 20 : 30} color="#f7f7e0" />
			</TouchableOpacity>
			<TouchableOpacity style={styles.nextIcon} onPress={() => setCurrentSlide((prev) => (prev + 1) % slides.length)}>
				<FontAwesome name="arrow-right" size={isSmallDevice ? 20 : 30} color="#f7f7e0" />
			</TouchableOpacity>

			<View style={styles.bubblesContainer}>
				{slides.map((_, index) => (
					<View key={index} style={[styles.bubble, currentSlide === index && styles.activeBubble]} />
				))}
			</View>
		</ImageBackground>
	);
};

const fontFamilies = {
	Bodoni72Smallcaps: "Bodoni72Smallcaps",
};






const generateStyles = (isSmallDevice, isTablet) =>
	StyleSheet.create({
		slideShow: {
			flex: 1,
			justifyContent: "space-between",
			alignItems: "center",
			resizeMode: "cover"
		},
		leftCornerTitle: {
			fontSize: isSmallDevice ? 35 : isTablet ? 70 : 80,
			textAlign: "left",
			color: "#f7f7e0",
			textShadowOffset: { width: 0, height: 5 },
			textShadowRadius: 6,
			textShadowColor: "rgba(0, 0, 0, 0.3)",
			marginLeft: 10,
			marginTop: 20,
			fontFamily: fontFamilies.Bodoni72Smallcaps,
		},
		leftCornerDescription: {
			fontSize: isSmallDevice ? 18 : isTablet ? 32 : 38,
			textAlign: "left",
			color: "#f7f7e0",
			textShadowOffset: { width: 0, height: 5 },
			textShadowRadius: 6,
			textShadowColor: "rgba(0, 0, 0, 0.3)",
			marginLeft: 10,
			fontFamily: fontFamilies.Bodoni72Smallcaps,
		},
		leftCorner: {
			position: "absolute",
			left: isSmallDevice ? 0 : 25,
			bottom: isSmallDevice ? 25 : 50,
		},
		rightCorner: {
			position: "absolute",
			right: isSmallDevice ? 0 : 50,
			top: isSmallDevice ? 25 : 50,
		},
		rightCornerTitle: {
			fontSize: isSmallDevice ? 30 : isTablet ? 85 : 90,
			textAlign: isSmallDevice ? "center" : isTablet ? "left" : "left",
			color: "#f7f7e0",
			textShadowOffset: { width: 0, height: 5 },
			textShadowRadius: 6,
			textShadowColor: "rgba(0, 0, 0, 0.3)",
			fontFamily: fontFamilies.Bodoni72Smallcaps,
			right: isSmallDevice ? 0 : isTablet ? -10 : -10,
		},
		button: {
			width: isSmallDevice ? 150 : isTablet ? 350 : 380,
			height: isSmallDevice ? 35 : isTablet? 50 : 55,
			borderRadius: isSmallDevice ? 22 : 32,
			backgroundColor: "#224332",
			justifyContent: "center",
			alignItems: "center",
			marginTop: 10,
			marginHorizontal: 3,
			marginRight: 10
		},
		buttonText: {
			fontSize: isSmallDevice ? 15 : isTablet ? 24 : 28,
			textAlign: "center",
			color: "#f7f7e0",
			textShadowOffset: { width: 10, height: 5 },
			textShadowRadius: 10,
			textShadowColor: "rgba(0, 0, 0, 0.16)",
			fontFamily: fontFamilies.Bodoni72Smallcaps,
		},
		prevIcon: {
			position: "absolute",
			left: 10,
			top: "50%",
		},
		nextIcon: {
			position: "absolute",
			right: 10,
			top: "50%",
		},
		bubblesContainer: {
			flexDirection: "row",
			justifyContent: "center",
			alignItems: "center",
			top: isSmallDevice ? 5 : isTablet ? 25 : 50,

		},
		bubble: {
			width: isSmallDevice ? 5 : isTablet ? 10 : 10,
			height: isSmallDevice ? 5 : isTablet ? 10 : 10,
			borderRadius: 5,
			backgroundColor: "#f7f7e0",
			marginHorizontal: 10,
			opacity: 0.5,
		},
		activeBubble: {
			opacity: 1,
		},
		row: {
			flexDirection: "row",
			flex: 1,
			textAlign: "center",
			justifyContent: "center",
			alignItems: "center",
		},
	});

const { width } = Dimensions.get("window");
const isSmallDevice = width < 600;
const isTablet = width < 1400 && width > 600;
const styles = generateStyles(isSmallDevice, isTablet);

export default SlideShow;
