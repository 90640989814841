import React, { useState, useEffect, useRef } from "react";
import { Platform, View, Text, ScrollView, ImageBackground, Animated, Pressable, Linking, Image, Dimensions } from "react-native";
import { useNavigation } from "@react-navigation/native";
import VideoBackground from "../Widgets/VideoBackground";
import styles from "../Styles/GolfStyles";
import NavbarHeader from "../Navigation/NavBarHeader";
import Footer from "../Navigation/Footer";
import { Link, animateScroll as scroll } from "react-scroll";
import Rates from "../Widgets/Rates";
import Leagues from "../Widgets/Leagues";
import HIOC from "../Widgets/HIOC";
import MembershipTypes from "../Widgets/MembershipTypes";
import StayAndPlayPopup from "../Widgets/StayAndPlayPopup";
import { usePageAnimations } from '../hooks/usePageAnimations';

const { width } = Dimensions.get("window");
const isSmallDevice = width < 600;
const isTablet = width < 1500 && width > 600;

const Golf = () => {
	const scrollViewRef = useRef(null);
	const navigation = useNavigation();
	const [scrollY, setScrollY] = useState(0);
	const [isContainerVisible, setIsContainerVisible] = useState(true);
	const [containerOpacity] = useState(new Animated.Value(1)); // Fully visible by default
	const [showHoleButtons, setShowHoleButtons] = useState(false);
	const [showStayAndPlayPopup, setShowStayAndPlayPopup] = useState(false);

	const [currentVideoIndex, setCurrentVideoIndex] = useState(() => Math.floor(Math.random() * 18)); // Random initial index
	const [fadeIn, setFadeIn] = useState(true);

	// State to track the active widget
	const [activeWidget, setActiveWidget] = useState(null);

	const videos = Array.from({ length: 18 }, (_, i) => `../assets/Videos/Hole${i + 1}.mp4`);

	const { getHeaderStyle, getContentStyle, getButtonStyle } = usePageAnimations();

	const handleVideoEnd = () => {
		console.log("Video ended, loading next video"); // Add this for debugging
		setFadeIn(false);
		setTimeout(() => {
			setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videos.length);
			setFadeIn(true);
		}, 500); // Delay for fade-out transition
	};

	const handleLinkPress = (pageName) => {
		
		// Check if the pageName is "Golf"
		if (pageName === "Golf") {
			// Calculate the y-coordinate of the golf section
			const yOffset = styles.golfContainer.paddingTop; // Adjust as needed

			// Scroll to the golf section
			scrollViewRef.current.scrollTo({ y: yOffset, animated: true });
		} else {
			// For other links, you can implement navigation logic here
			console.log(`Navigating to ${pageName}`);
			navigation.navigate(pageName);
		}
	};

	const handlePhonePress = () => {
		console.log("Phone Pressed");
		if (Platform.OS === 'web') {
			alert("Please call (814) 445-5200");
		} else {
			// Open the phone app with the provided phone number
			Linking.openURL("tel:(814)445-5200");
		}
	};

	const handleProShop = () => {
		// Open the phone app with the provided phone number
		Linking.openURL("tel:(814)445-5179");
	};

	const handleScroll = (event) => {
		// Update the scrollY state as the user scrolls
		const offsetY = event.nativeEvent.contentOffset.y;
		setScrollY(offsetY);
	};

	const toggleHoleButtons = () => {
		setShowHoleButtons(!showHoleButtons);
	};

	const handleSelectHole = (holeIndex) => {
		handleHideContainer();
		setTimeout(() => {
			setCurrentVideoIndex(holeIndex);
		}, 500);
	};

	const handleHideContainer = () => {
		console.log("Hover In");
		Animated.timing(containerOpacity, {
			toValue: 0,
			duration: 500,
			useNativeDriver: true,
		}).start();
		setTimeout(() => {
			setIsContainerVisible(false);
		}, 500);
	};

	const handleShowContainer = () => {
		console.log("Hover Out");
		Animated.timing(containerOpacity, {
			toValue: 1,
			duration: 500,
			useNativeDriver: true,
		}).start();
		setIsContainerVisible(true);
	};

	const handleToggleContainer = () => {
		setIsContainerVisible(!isContainerVisible);
		if (isContainerVisible) {
			Animated.timing(containerOpacity, { toValue: 0, duration: 500, useNativeDriver: true }).start();
		} else {
			Animated.timing(containerOpacity, { toValue: 1, duration: 500, useNativeDriver: true }).start();
		}
	};

	// Function to handle downloading a PDF file
	const handlePdfDownload = async (pdfUrl) => {
		try {
			const response = await fetch(pdfUrl);
			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}

			const blob = await response.blob();
			const url = window.URL.createObjectURL(blob);

			// Create a new anchor element
			const a = document.createElement("a");
			a.href = url;
			a.download = pdfUrl.split("/").pop(); // Set the file name
			document.body.appendChild(a);
			a.click();

			// Clean up
			window.URL.revokeObjectURL(url);
			document.body.removeChild(a);
		} catch (error) {
			console.error("Download error:", error.message);
		}
	};

	// Function to handle the navigation to the GHIN website
	const handleNavigateToGHIN = () => {
		Linking.openURL("https://www.ghin.com/login");
	};

	// Function to handle widget selection
	const handleWidgetSelect = (widgetName) => {
		setActiveWidget(widgetName);
	};

	const handleStayAndPlayClick = () => {
		if (Platform.OS === 'web') {
			setShowStayAndPlayPopup(true);
		} else {
			handleLinkPress("tel:+18144459611");
		}
	};

	useEffect(() => {
		console.log("Current video source: ", videos[currentVideoIndex]);
		handleWidgetSelect("MembershipTypes");
	}, [currentVideoIndex]);

	return (
		<View style={styles.body}>
			<StayAndPlayPopup 
				isVisible={showStayAndPlayPopup}
				onClose={() => setShowStayAndPlayPopup(false)}
				onCall={handleStayAndPlayClick}
			/>
			<View style={{ position: "sticky", top: 0, zIndex: 1000 }}>
				<NavbarHeader />
			</View>

			<ScrollView
				ref={scrollViewRef}
				style={styles.body}
				onScroll={handleScroll}
				scrollEventThrottle={16}
			>
				<View style={styles.section}>
					<ImageBackground source={require("../assets/Example_Background.png")} style={styles.background}>
						<VideoBackground
							style={{ ...styles.videoBackground, width: '100%', height: '100%', resizeMode: "cover" }}
							key={currentVideoIndex}
							isMuted={true}
							src={videos[currentVideoIndex]}
							onEnded={handleVideoEnd}
						/>
						<View style={{ position: "relative", width: "100%", height: "100vh", zIndex: 0 }}>
							<Pressable style={styles.showVideoButton} onHoverIn={handleHideContainer} onHoverOut={handleShowContainer} onPress={handleToggleContainer}>
								{isContainerVisible ? <Text style={styles.showVideo}>Show</Text> : <Text style={styles.showVideo}>Hide</Text>}
								<Text style={styles.showVideo}>Video</Text>
							</Pressable>

							{!isSmallDevice && (
								<Pressable style={styles.showFlyoversButton} onPress={toggleHoleButtons}>
									<Text style={styles.showVideo}>{showHoleButtons ? "Hide Flyovers" : "Show Flyovers"}</Text>
								</Pressable>
							)}
							{showHoleButtons && (
								<View style={styles.holesContainer}>
									{Array.from({ length: 18 }, (_, index) => (
										<Pressable key={index} style={styles.holeButton} onPress={() => handleSelectHole(index)}>
											<Text style={styles.holeButtonText}>Hole {index + 1}</Text>
										</Pressable>
									))}
								</View>
							)}

							{isContainerVisible && (
								<Animated.View style={{ opacity: containerOpacity }}>
									<Animated.View style={getHeaderStyle()}>
										<Text style={styles.titleText}>Golf</Text>
									</Animated.View>

									<Animated.View style={getContentStyle()}>
										<View style={styles.navBar}>
											<Link to="Pro Shop" smooth={true} duration={500} offset={-60}>
												<Text style={styles.navBarText}>Pro Shop</Text>
											</Link>

											<Link to="Members" smooth={true} duration={500} offset={-60}>
												<Text style={styles.navBarText}>Members</Text>
											</Link>

											<Pressable 
												style={styles.navBarTextSP} 
												onPress={handleStayAndPlayClick}>
												<Text style={styles.navBarText}>Stay and Play</Text>
											</Pressable>
										</View>
									</Animated.View>

									<Animated.View style={getContentStyle()}>
										<View style={styles.mainContainer}>
											<Text style={styles.mainContainerText}>From dinner shows to concerts and charity events, there's always a reason to come out to the Club. Our members enjoy the family-friendly atmosphere and a plethora of amenities to create fun-filled experiences, long-lasting friendships, and cherished memories.</Text>
										</View>
									</Animated.View>
								</Animated.View>
							)}
						</View>
					</ImageBackground>
				</View>

				<Animated.View style={getContentStyle()}>
					<View id={"Pro Shop"} style={styles.section}>
						<ImageBackground source={require("../assets/Pro Shop.jpg")} style={styles.background}>
							<Text style={styles.headerText}>Pro Shop</Text>

							<View style={styles.proShop}>
								<Text style={styles.proShopHeaderText}>Pro Shop</Text>
								<Text style={styles.proShopDescriptionText}>Whatever your needs, stop by the Somerset Country Club Pro Shop and let our friendly and professional staff provide assistance with all your golfing attire and club fitting needs, or help finding that perfect gift. To check the availability of certain items, call the pro shop.</Text>
								<Pressable style={styles.button} onPress={handleProShop}>
									<Text style={styles.buttonText}>Pro Shop</Text>
								</Pressable>
							</View>
						</ImageBackground>
					</View>
				</Animated.View>

				<Animated.View style={getContentStyle()}>
					<View id={"Members"} style={styles.section}>
						<ImageBackground source={require("../assets/Example_Background_2.png")} style={styles.background}>
							<Text style={styles.headerText}>Members</Text>
							<View style={styles.navBar1}>
								<Pressable onPress={() => handleWidgetSelect("Rates")}>
									<Text style={styles.navBarText1}>Rates</Text>
								</Pressable>
								<Pressable
									to="Tournaments"
									smooth={true}
									duration={500}
									offset={-60}
									onPress={() => {
										handlePdfDownload("../assets/2024 MEMBER EVENTS.pdf");
										handlePdfDownload("../assets/2024 Scorecard 1.png");
										handlePdfDownload("../assets/2024 Scorecard 2.png");
									}}>
									<Text style={styles.navBarText1}>Tournaments</Text>
								</Pressable>

								{!isSmallDevice && (
									<Pressable onPress={() => handleWidgetSelect("HIOC")}>
										<Text style={styles.navBarText1}>HIOC</Text>
									</Pressable>
								)}

								{!isSmallDevice && (
									<Pressable to="Handicap" smooth={true} duration={500} offset={-60} onPress={() => handleNavigateToGHIN()}>
										<Text style={styles.navBarText1}>Handicap Lookup</Text>
									</Pressable>
								)}

								<Pressable onPress={() => handleWidgetSelect("Leagues")}>
									<Text style={styles.navBarText1}>Leagues</Text>
								</Pressable>
							</View>

							{activeWidget === "MembershipTypes" && <MembershipTypes />}
							{activeWidget === "Rates" && <Rates />}
							{activeWidget === "Leagues" && <Leagues />}
							{activeWidget === "HIOC" && <HIOC />}
						</ImageBackground>
					</View>
				</Animated.View>

				<Footer />
			</ScrollView>
		</View>
	);
};

export default Golf;
