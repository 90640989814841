import React from "react";

import { View, Text, ScrollView, ImageBackground, Pressable, Dimensions, StyleSheet, Linking } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { FontAwesome } from '@expo/vector-icons';  // Updated import
import SocialMediaWidget from "../Widgets/SocialMediaWidget";

const Stack = createStackNavigator();

const Footer = () => {
	const navigation = useNavigation();
	const { width } = Dimensions.get("window");
	const isSmallDevice = width < 600;
	const isTablet = width < 1500 && width > 600;

	const handleLinkPress = (pageName) => {
		// You can implement navigation logic here to navigate to the desired page
		console.log(`Navigating to ${pageName}`);
		navigation.navigate(pageName);
	};

	const handlePhonePress = () => {
		// Open the phone app with the provided phone number
		Linking.openURL("tel:(814)445-5200");
	};

	const handleEmailPress = () => {
		// Open the email app with the provided email address
		Linking.openURL("mailto:club@somersetcountryclub.com");
	};

	const handleAddressPress = () => {
		// Open the maps app with the provided address for directions
		Linking.openURL("https://maps.google.com/?q=416+Plank+Road+Somerset+PA+15501");
	};

	return (
		<View style={styles.footer}>
			{/* Left Section */}
			<View style={styles.leftSection}>
				{/* Phone */}
				<Pressable onPress={handlePhonePress}>
					<View style={styles.iconTextContainer}>
						<FontAwesome name="phone" size={isSmallDevice ? 18 : isTablet ? 20 : 25} color="#f7f7e0" />

						<Text style={styles.leftText}>(814) 445-5200</Text>
					</View>
				</Pressable>

				{/* Email */}
				<Pressable onPress={handleEmailPress}>
					<View style={styles.iconTextContainer}>
						<FontAwesome name="at" size={isSmallDevice ? 18 : isTablet ? 20 : 25} color="#f7f7e0" />

						<Text style={styles.leftText}>club@somersetcountryclub.com</Text>
					</View>
				</Pressable>

				{/* Address */}
				<Pressable onPress={handleAddressPress}>
					<View style={styles.iconTextContainer}>
						<FontAwesome name="map-marker" size={isSmallDevice ? 18 : isTablet ? 20 : 25} color="#f7f7e0" />
						<Text style={styles.leftText}>416 Plank Road Somerset, PA 15501</Text>
					</View>
				</Pressable>
			</View>
			{!isSmallDevice && (
				// Middle Section
				<>
					<View style={styles.middleSection}>
						{/* First Column */}
						<View style={styles.column}>
							<Pressable onPress={() => handleLinkPress("Home")}>
								<Text style={styles.middleText}>Home</Text>
							</Pressable>
							<Pressable onPress={() => handleLinkPress("Golf")}>
								<Text style={styles.middleText}>Golf</Text>
							</Pressable>
							<Pressable onPress={() => handleLinkPress("Events")}>
								<Text style={styles.middleText}>Events</Text>
							</Pressable>
							<Pressable onPress={() => handleLinkPress("Contact")}>
								<Text style={styles.middleText}>Contact</Text>
							</Pressable>
						</View>

						{/* Second Column */}
						<View style={styles.column}>
							<Pressable onPress={() => handleLinkPress("Ecents")}>
								<Text style={styles.middleText}>Meetings</Text>
							</Pressable>
							<Pressable onPress={() => handleLinkPress("Club")}>
								<Text style={styles.middleText}>Pro Shop</Text>
							</Pressable>
							<Pressable onPress={() => handleLinkPress("Club")}>
								<Text style={styles.middleText}>Membership</Text>
							</Pressable>
							<Pressable onPress={() => handleLinkPress("Events")}>
								<Text style={styles.middleText}>Calendar</Text>
							</Pressable>
						</View>

						{/* Third Column */}
						<View style={styles.column}>
							<Pressable onPress={() => handleLinkPress("Events")}>
								<Text style={styles.middleText}>Banquets</Text>
							</Pressable>
							<Pressable onPress={() => handleLinkPress("Contact")}>
								<Text style={styles.middleText}>Get Directions</Text>
							</Pressable>
							<Pressable onPress={() => handleLinkPress("Events")}>
								<Text style={styles.middleText}>Dining Menus</Text>
							</Pressable>
							<Pressable onPress={() => handleLinkPress("Contact")}>
								<Text style={styles.middleText}>Job Openings</Text>
							</Pressable>
						</View>
					</View>

					{/* Right Section */}
					<View style={styles.rightSection}>
						{/* First Column */}
						<View style={styles.column}>
							<Text style={styles.rightTextHeader}>Winter Hours: Nov - Dec</Text>
							<Text style={styles.rightText}>Monday: Closed</Text>
							<Text style={styles.rightText}>Tuesday: 4PM - 9PM</Text>
							<Text style={styles.rightText}>Wed - Sat: 10AM - 9PM</Text>
							<Text style={styles.rightText}>Sunday: 10AM - 8PM</Text>
						</View>

						{/* Second Column */}
						<View style={styles.column}>
							<Text style={styles.rightTextHeader}>Club Hours: Apr - Oct</Text>
							<Text style={styles.rightText}>Mon - Sat: 9AM - 9PM</Text>
							<Text style={styles.rightText}>Sunday: 9AM - 8 PM</Text>
							<Text style={styles.rightText}>Closed Months: Jan - Mar</Text>
						</View>
					</View>
				</>
			)}
			<div style={{ position: "sticky", zIndex: 1000 }}>
				<SocialMediaWidget style={styles.socialMediaWidget} />
			</div>
		</View>
	);
};

const { width } = Dimensions.get("window");
const isSmallDevice = width < 600;
const isTablet = width < 1500 && width > 600;

const fontFamilies = {
	bodoni72Smallcaps: "Bodoni72Smallcaps",
};

const styles = StyleSheet.create({
	footer: {
		flex: 1, // Each section takes up an equal portion of the screen's height
		maxHeight: 250,
		flexDirection: "row",
		justifyContent: "space-between",
		paddingHorizontal: 50,
		paddingVertical: 10,
	},
	// Left Section Styles
	leftSection: {
		width: isSmallDevice ? 300 : "25%",
		height: 157,
		background: "transparent",
	},
	leftText: {
		fontFamily: "Orpheus Pro",
		fontWeight: "normal",
		fontSize: isSmallDevice ? 16 : isTablet ? 16 : 22,
		textAlign: "left",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 0.16)",
		textShadowOffset: { width: 10, height: 5 },
		textShadowRadius: 10,
		lineHeight: isSmallDevice ? 50 : 60,
		paddingHorizontal: 10,
	},
	iconTextContainer: {
		flexDirection: "row",
		alignItems: "center",
	},

	// Middle Section Styles
	middleSection: {
		flexDirection: "row",
		marginTop: 5,
		marginRight: 50,
	},
	column: {
		flex: 1,
		alignItems: "center",
	},
	middleText: {
		fontFamily: "Orpheus Pro",
		fontWeight: "500",
		fontSize: isSmallDevice ? 16 : isTablet ? 16 : 22,
		textAlign: "center",
		color: "#f7f7e0",
		minWidth: isSmallDevice ? 180 : isTablet ? 150 : 200,
		lineHeight: 45,
	},

	// Right Section Styles
	rightSection: {
		flexDirection: "row",
		display: isSmallDevice ? "none" : "flex",
		justifyContent: "space-evenly"
	},
	rightText: {
		width: isSmallDevice ? 180 : isTablet ? 200 : 230,
		height: 25,
		fontFamily: "Orpheus Pro",
		fontSize: isSmallDevice ? 16 : isTablet ? 16 : 22,
		lineHeight: 50,
		textAlign: "left",
		color: "#f7f7e0",
	},
	rightTextHeader: {
		width: isSmallDevice ? 180 : isTablet ? 200 : 230,
		fontSize: isSmallDevice ? 16 : isTablet ? 14 : 20,
		fontFamily: "Orpheus Pro",
		fontWeight: "bold",
		lineHeight: 50,
		textAlign: "left",
		color: "#f7f7e0",
	},
	socialMediaWidget: {
		position: "absolute",
		bottom: isSmallDevice ? 10 : isTablet ? 20 : 25,
		right: isSmallDevice ? 25 : isTablet ? 200 : 200,
	}
});

export default Footer;
